<template>
    <!-- <div class="outer" ref="scroll"> -->
    <!-- <div class="pulldown-wrapper">
            <div v-show="beforePullDown">
                <span>下拉刷新</span>
            </div>
            <div v-show="!beforePullDown">
                <div v-show="isPullingDown">
                    <span>加载中...</span>
                </div>
                <div v-show="!isPullingDown">
                    <span>刷新成功</span>
                </div>
            </div>
        </div> -->
    <div class="container">
        <div class="top-box">
            <img class="background" src="/img/main/background.png" alt="">
			
			<div class="top_tit">
				<div>
					<div class="top">修改密码</div>
					<div class="bottom">
						<div class="phone">温馨提示：请您修改密码, 进行后续的操作</div>
					</div>
				</div>
				<div @click="logout" class="logut_btn">退出</div>
			</div>
            
        </div>
        <div class="forget-box">
            <div class="forget-box__title">
                <div>新密码</div>
            </div>
            <el-input class="forget-box__input" placeholder="请输入6-20位字母、数字或符号的组合" v-model="pwd1" show-password></el-input>
            <div class="forget-box__title">
                <div>确认密码</div>
            </div>
            <el-input class="forget-box__input" placeholder="请再次输入新密码" v-model="pwd2" show-password></el-input>
        </div>
        <div class="forget-btn-box" style="flex-direction: column">
            <el-button class="login-btn" @click="confirm" type="primary" v-throttle>确定
            </el-button>
            <div class="footer">北京京学科技发展集团有限公司提供技术支持</div>
        </div>
    </div>
</template>
<script>

export default {
    name: "Forget",
    data() {
        return {
            isCheck: '',
            pwd1: '',
            pwd2: ''
        };
    },
    watch: {

    },
    components: {

    },
    mounted() {
        this.isCheck = this.$route.query.is_organ_check
        this.name = localStorage.getItem('name')
        this.username = localStorage.getItem('username')
        this.role = localStorage.getItem('role')
		this.organization_id = localStorage.getItem('organization_id') 
    },
    methods: {
		logout:function(){
			localStorage.removeItem("token");
			this.$router.push({path: '/user/login',replace:true})
		},
        confirm() {
			
			if(!this.pwd1||!this.pwd2){
				return this.$message({
				  message: '请输入完整密码！',
				  type: "warning",
				});
			}
			if(!/^[0-9a-zA-z]{6,12}$/.test(this.pwd1)){
				return this.$message({
				  message: '密码必须是6位以上字母或数字的组合',
				  type: "warning",
				});
			};
			if(this.pwd1!=this.pwd2){
				return this.$message({
				  message: '两次输入的密码不一致！',
				  type: "warning",
				});
			}
			this.$api({
			    method: 'post',
			    url: 'api/resetpswd',
				data:{newpswd:this.pwd1,repswd:this.pwd2},
			}).then(res=>{
				localStorage.removeItem("token"); 
				this.$router.push({path: '/user/login',replace:true})
				return true;
				
			})
            
        }
    },
};
</script>
<style lang="scss">
	.top_tit{ display: flex; justify-content: space-between; align-items: center;}
	.logut_btn{ background: rgba(0,0,0,.3) url(~@/assets/index/icon_logout.png) no-repeat 0.35rem center;
	 padding-left: 1.05rem; border-radius: 0.2rem; font-size: 1rem; height: 1.8rem; line-height: 1.8rem; width: 4rem; background-size:1rem 1rem; color:#fff; margin-right:1rem;}
	
.el-select-dropdown__item {
    width: 100%;
}
.container {
    height: 100%;

    .top-box {
        position: fixed;
        z-index: 2;
        top: 0;
        width: 100%;
        height: 125px;
        // background-image: url('/img/main/background.png');
        // background-size: 100% 125px;

        .background {
            position: absolute;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 125px;
        }

        .top {
            margin: 25px 0 0 15px;
            text-align: left;
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 27px;
        }

        .bottom {
            z-index: 99;
            margin: 4px 12px 0 15px;
            display: flex;
            align-items: center;

            .phone {
                margin-right: auto;
                font-size: 14px;
                font-weight: 400;
                color: #FFF;
                line-height: 20px;
            }

            .guide {
                font-size: 13px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 20px;
                background-clip: text;
            }

            .icon {
                margin-left: 3px;
                width: 13px;
                height: 13px;
            }
        }
    }

    .forget-box {
        position: absolute;
        top: 100px;
        z-index: 99;
        height: calc(100% - 100px);
        width: 100%;
        overflow-y: scroll;
        background: #F7F8FA;
        border-radius: 10px 10px 0px 0px;
		padding: 0 0.9375rem 9.375rem 0.9375rem;

        .forget-box__title {
            margin-top: 15px;
            display: flex;
            text-align: left;
            align-items: center;
            font-size: 15px;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
        }
        
        .forget-box__input {
            margin-top: 15px;
            .el-input__inner {
                background: #ffffff;
            }
        }

    }

    .forget-btn-box {
        z-index: 100;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 111px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px 0px rgba(242, 242, 242, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;

        .login-btn {
            width: 323px;
            font-size: 19px;
            color: #ffffff;
        }

        .footer {
            bottom: 10px;
        }
    }

}
</style>